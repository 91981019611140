<!-- 
    @Description 文件描述
    @Author 王猛龙（王磊）
    @Date 2020-12-10 09:29:27
 * @LastEditTime 2021-06-20 12:06:25
 * @LastEditors 王猛龙（王磊）
-->
<template>
  <div class="home main-container">
    <p class="home-item">RAYCHON+ Philosophie</p>
    <p class="home-item">
      Wir bringen die Innovation mit Effizienz und CO2-neutrale Lösung .
    </p>
    <p class="home-item">
      Wir führen die Geschäfte mit Win-Win Solution durch.
    </p>
    <img class="home-img" src="../../assets/bg1.png" alt="home bg 1" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

@Options({
  components: {
    HelloWorld,
  },
})
export default class Home extends Vue {}
</script>

<style scoped  lang="scss">
.home-item {
  font-size: 24px;
  font-family: Arial Rounded MT Bold;
  padding-left: 10px;
}
.home-img {
  width: 100%;
}
</style>