<template>
  <div id="nav">
    <div class="nav-left">
      <router-link to="/" class="fist-nav-item nav-item">Über RAYCHON+</router-link> |
      <router-link to="/products" class="nav-item">Angebot</router-link> |
      <router-link to="/about" class="nav-item">Impressum</router-link>
    </div>
    <div class="nav-right">
        <img src="./assets/logo1.jpg"/>
      <!-- <div class="logo-title">
        <div class="first-title">RAYCHON</div>
        <div class="second-title">Engineering Consulting, Import & Export</div>
        <img src="./assets/logo.jpg" />
      </div> -->
    </div>
  </div>
  <div class="page-body">
    <router-view />
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 50px 0;
  min-height: 70vh;
  width: 1200px;
  margin: 0 auto;
}

.page-body {
  text-align: left;
  background: -web;
  background-image: linear-gradient(to right, #0e0e0e, #5b5757);
  min-height: 65vh;
  margin: 0;
  padding: 40px;
  color: #fff;
  display: flex;
  align-items: center;

  .main-container {
    min-height: 250px;
    width: 100%;
  }
}

#nav {
  text-align: left;
  padding-bottom: 30px;
  height: 100px;
  display: flex;
  align-items: center;

  .nav-left {
    width: 70%;
    display: inline-block;
    line-height: 165px;
    height: 100px;

    .fist-nav-item {
         margin-left: 0px !important;
    }
    .nav-item {
      font-weight: bold;
      color: #2c3e50;
      font-size: 30px;
      margin-right: 10px;
      margin-left: 10px;
      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
  .nav-right {
    width: 30%;
    display: inline-block;

    img{
        width: 360px;
    }
    // .logo-title {
    //   .first-title {
    //     font-size: 66px;
    //     font-weight: bolder;
    //     font-family: Arial  rounded MT bold;
    //   }
    //   .second-title {
    //       font-weight: bolder;
    //       font-family: Arial  rounded MT bold;
    //   }
    //   img {
    //     width: 35px;
    //     height: auto;
    //     position: relative;
    //     right: 2%;
    //     bottom: 96px;
    //     float: right;
    //   }
    // }
  }
}
</style>
