/**
 * @Description 文件描述
 * @Author 王猛龙（王磊）
 * @Date 2020-12-10 09:29:27
 * @LastEditTime 2020-12-26 19:47:57
 * @LastEditors 王猛龙（王磊）
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home/index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: { index: 0 }, // meta对象的index用来定义当前路由的层级,由小到大,由低到高
    component: Home,
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views//Products/index.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About/index.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
